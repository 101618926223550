import React, { useEffect } from "react";
import "./OutflowSplitbillShare.css";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { fetchApi, validateTaxRates } from "../../services/api.service";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

// const convertObj = [
//   {
//     "id": "AUD",
//     "aud": null,
//     "gbp": "0.52",
//     "nzd": "1.09",
//     "usd": "0.67",
//   },
//   {
//     "id": "GBP",
//     "aud": "1.91",
//     "gbp": null,
//     "nzd": "2.07",
//     "usd": "1.27",
//   },
//   {
//     "id": "NZD",
//     "aud": "0.92",
//     "gbp": "0.48",
//     "nzd": null,
//     "usd": "0.61",
//   },
//   {
//     "id": "USD",
//     "aud": "1.50",
//     "gbp": "0.79",
//     "nzd": "1.63",
//     "usd": null,
//   }
// ]

const OutflowSplitbillShare = ({ splitbill, setSplitbill, amt, currency, gst }) => {
  const [splitbillshare, setSplitbillshare] = useState([]);
  const [selections, setSelections] = useState(Array(splitbillshare.length).fill(""));
  const [orgs, setOrgs] = useState([]);
  const [conversionRateObj, setConversionRateObj] = useState([])
  const [rateObj, setRateObj] = useState(null)
  const [convRate, setConvRate] = useState(null);

  // conversions rate api
  const conversionRates = async () => {
    const res = await fetchApi("/api/convrates");
    const data = await res.json();
    setConversionRateObj(data.payload);
  }
  useEffect(() => {
    conversionRates()
  }, []);
  // updating splitBill with the list item values
  useEffect(() => {
    setSplitbill({ ...splitbill, org_split: splitbillshare });
  }, [splitbillshare]);
  // organisation list api
  const getOrg = async () => {
    const urlOrg = await fetchApi("/api/orgs");
    const orglist = await urlOrg.json();
    setOrgs(orglist.palyload);
  };
  useEffect(() => {
    getOrg();
  }, []);

  const addSplitbillShare = (event) => {
    event.preventDefault();
    const newSplitbill = {
      id: Date.now(),
      company_abbr: "",
      description: "",
      split_per: 0,
      gst: false,
      gst_per: 0,
      gst_amount: 0,
      local_rate: 0,
      total_rate: 0,
      conversion_rate: 0,
      currency_val: null
    };
    setSplitbillshare([...splitbillshare, newSplitbill]);
  };

  const handleChange = (idx, event) => {
    const { name, value, checked } = event.target;

    let updatedBill;
    let t = 0.0; // Default GST percentage

    if (name === "gst") {
      if (checked && gst > 0) { // If the checkbox is checked, calculate the GST percentage
        if (currency === "NZD") {
          if (
            ["TNZ", "RNZ", "ONZ", "ZNZ", "RI", "OI", "ZI"].includes(
              splitbillshare[idx].company_abbr
            )
          ) {
            t = 15; // 0.15
          }
        } else if (currency === "AUD") {
          if (["RAU", "ZAU"].includes(splitbillshare[idx].company_abbr)) {
            t = 10; // 0.1
          }
        }
      }
      // console.log("total gst", t, "gst", gst)
      updatedBill = splitbillshare.map((newbill, index) =>
        idx === index
          ? {
            ...newbill,
            [name]: checked, // Set the GST checkbox state
            gst_per: t, // Set GST percentage
            gst_amount: (t * gst) / 100, // Calculate GST amount
            // gst_amount: (t * Number(newbill.split_per) * amt) / 100, // Calculate GST amount
          }
          : newbill
      );
    } else {
      console.log("else")
      updatedBill = splitbillshare.map((newbill, index) =>
        idx === index ? { ...newbill, [name]: value } : newbill
      );
    }
    setSplitbillshare(updatedBill);

    updatedBill = updatedBill.map((bill) => (
      {
        ...bill,
        conversion_rate: calculateConversion(bill),
        currency_val: calculateCurrVal(bill),
        local_rate: calculateLocalAmt(bill),
        total_rate: calculateTotalAmt(bill),
      }
    ))

    setSplitbillshare(updatedBill);
    setSplitbillshare(updatedBill);
    setSplitbill({ ...splitbill, org_split: updatedBill });
    const newSelections = [...selections];
    newSelections[idx] = event.target.value;
    setSelections(newSelections);
  };

  const calculateConversion = (bill) => {
    const { company_abbr } = bill;
    let selectedCurrency = "";
    switch (company_abbr) {
      case 'RUK': selectedCurrency = "gbp"; break;
      case 'ZAU':
      case 'RAU': selectedCurrency = "aud"; break;
      case 'RUS': selectedCurrency = "usd"; break;
      case 'RNZ':
      case 'TNZ':
      case 'ONZ':
      case 'OI':
      case 'RI':
      case 'ZI':
      case 'ZNZ': selectedCurrency = "nzd"; break;
      default: selectedCurrency = ""; break;
    }
    let findCurrency = conversionRateObj.find((x) => x.id == currency);
    setRateObj(findCurrency);
    // console.log("object", findCurrency, findCurrency[selectedCurrency])
    const value = (findCurrency && findCurrency[selectedCurrency]) ? findCurrency[selectedCurrency] : 1;
    if (isNaN(value)) {
      console.error(`Invalid conversion rate for ${selectedCurrency} in ${currency}`);
      return 1;
    }
    return Number(value)
  }

  const calculateCurrVal = (bill) => {
    const { company_abbr } = bill;
    let selectedCurrency = "";
    switch (company_abbr) {
      case 'RUK': selectedCurrency = "GBP €"; break;
      case 'ZAU':
      case 'RAU': selectedCurrency = "AUD $"; break;
      case 'RUS': selectedCurrency = "USD $"; break;
      case 'RNZ':
      case 'TNZ':
      case 'ONZ':
      case 'OI':
      case 'RI':
      case 'ZI':
      case 'ZNZ': selectedCurrency = "NZD $"; break;
      default: selectedCurrency = ""; break;
    }
    return selectedCurrency;
  }

  const calculateTotalAmt = (bill) => {
    const { split_per, conversion_rate } = bill;
    // console.log(conversion_rate, bill, '==================>>>>>>>')
    setConvRate(conversion_rate)
    let splitValue = (split_per * amt) / 100;
    return splitValue * (Number(conversion_rate)).toFixed(2);
  }

  const calculateLocalAmt = (bill) => {
    const { split_per } = bill;
    const nzdRate = rateObj?.nzd !== null ? rateObj?.nzd : 1;
    // Calculate and round to 2 decimal places
    const result = split_per * amt * nzdRate;
    return Math.round(result * 100) / 100;
  }

  const removeItem = (id, value) => {
    setSelections(selections.filter((data) => {
      return data != value?.company_abbr;
    }))

    setSplitbillshare(
      splitbillshare.filter((ele) => {
        return ele.id !== id;
      })
    );
  };

  // console.log("convRate", convRate)
  // console.log("splitbill", splitbill)

  return (
    <div style={{ padding: "10px" }}>
      <Table
        style={{ width: "100%" }}
        component={Paper}
        size="small"
        aria-label="a dense table"
        sx={{ "& .MuiTableCell-root": { fontSize: "12px", fontWeight: "600" } }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              align="left"
              style={{ width: "100px", borderRight: "1px solid #cccccc" }}
            >
              Company*
            </TableCell>
            <TableCell
              align="left"
              style={{ width: "200px", borderRight: "1px solid #cccccc" }}
            >
              Description*
            </TableCell>
            <TableCell
              align="left"
              style={{ width: "80px", borderRight: "1px solid #cccccc" }}
            >
              Split (%)*
            </TableCell>
            <TableCell
              align="left"
              style={{ width: "80px", borderRight: "1px solid #cccccc" }}
            >
              GST
            </TableCell>
            <TableCell
              align="left"
              style={{ width: "120px", borderRight: "1px solid #cccccc" }}
            >
              Total Rate
            </TableCell>
            <TableCell align="left" style={{ width: "120px" }}>
              Local
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {splitbillshare.map((element, index) => (
            <TableRow key={element.id}>
              <TableCell
                align="left"
                style={{ borderRight: "1px solid #cccccc" }}
              >
                <select
                  type="text" required
                  name="company_abbr"
                  className="outflowsplitbillshare"
                  onChange={(event) => handleChange(index, event)}
                  style={{ width: "100%" }}
                >
                  <option>Company*</option>
                  {orgs.map((org, idx) => (
                    <option value={org.abbr} key={idx}>
                      {org.name}
                    </option>
                  ))}
                </select>
              </TableCell>
              <TableCell
                align="left"
                style={{ borderRight: "1px solid #cccccc" }}
              >
                <TextField
                  id="outlined-basic"
                  size="small"
                  type="text"
                  name="description"
                  inputProps={{ maxlength: 2000, style: { fontSize: "12px", height: 12 } }}
                  value={element.quantity}
                  onChange={(event) => handleChange(index, event)}
                  style={{ width: "100%" }}
                />
              </TableCell>
              <TableCell
                align="left"
                style={{ borderRight: "1px solid #cccccc" }}
              >
                <TextField
                  id="outlined-basic"
                  size="small"
                  type="number"
                  name="split_per"
                  inputProps={{ min: 0, style: { fontSize: "12px", height: 12 } }}
                  value={element.price}
                  onChange={(event) => handleChange(index, event)}
                />
              </TableCell>
              <TableCell
                align="left"
                style={{ borderRight: "1px solid #cccccc" }}
              >
                <input
                  type="checkbox"
                  name="gst"
                  className="special-input"
                  checked={element.gst}
                  onChange={(event) => handleChange(index, event)}
                />
                <span>
                  -&nbsp;&nbsp;&nbsp;&nbsp;
                  {`${element.gst_amount ? element.currency_val : ""} ${element.gst_amount ? element.gst_amount : ""} `}
                  {/* {(element.gst_per * (Number(element.split_per) * amt)) / 100} */}
                </span>
              </TableCell>
              <TableCell
                align="left"
                style={{ borderRight: "1px solid #cccccc" }}
              >
                <TextField
                  id="outlined-basic"
                  size="small"
                  type="text"
                  name="total"
                  value={` ${element.currency_val || ""} ${parseFloat((element.total_rate).toFixed(2))}`}
                  inputProps={{ style: { fontSize: "12px", height: 12, background: '#f4f4f4' } }}
                />
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <TextField
                  id="outlined-basic"
                  size="small"
                  type="text"
                  name="local-nzd"
                  value={`NZD $ ${parseFloat((element.local_rate).toFixed(2)) || 0}`}
                  inputProps={{ style: { fontSize: "12px", height: 12, background: '#f4f4f4' } }}
                />
                {/* {index} */}
                <IconButton
                  onClick={() => removeItem(element.id, element)}
                  sx={{ width: 0 }}
                >
                  <RemoveCircleOutlineIcon fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div>
        <FontAwesomeIcon
          icon={faPlusCircle}
          onClick={addSplitbillShare}
          style={{
            color: "#0056b3",
            height: "30px",
            margin: "10px 0",
            display: "flex",
            justifyContent: "flex-start",
          }}
        />
      </div>
    </div>
  );
};

export default OutflowSplitbillShare;
