import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { fetchApi } from '../../../services/api.service';
import SnackbarMsg from '../../../components/superadmin-home/components/Common/Snackbar';
import LoadingButton from '@mui/lab/LoadingButton';

const SendToXero = ({ openXeroConfModal, data, handleCloseXeroConfModal, callBillApi, setCallBillApi }) => {
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [apiMsg, setApiMsg] = useState(null)
    const [MsgColor, setMsgColor] = useState(null)
    const [responseLoading, setResponseLoading] = useState(false)
    // send to xero function
    const handleSendToXero = async (elem) => {
        const sentToXeroPayload = {
            bill: {
                id: elem.id,
                invoice_no: elem.invoice_no,
                gl_code: elem.gl_code,
                due_date: elem.due_date, /* yyyy-mm-dd in string */
                issue_date: elem.issue_date,  /* yyyy-mm-dd in string */
                reference: elem.reference,
                organisation: {
                    xero_tenant_id: elem.organisation?.xero_tenant_id,
                    id: elem.organisation?.id
                },
                vendor: {
                    id: elem.vendor?.id
                },
                outflowbill_products: elem.outflowbill_products.map((product) => ({   /* binding only neccessry field in the array from outflowbill_products array */
                    description: product.description,
                    quantity: product.quantity,
                    price: product.price,
                    tax_rate: product.tax_rate
                }))
            }
        }
        try {
            setResponseLoading(true)
            let response = await fetchApi(`/api/xero/outflow-bill/send-to-xero`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(sentToXeroPayload)
            });

            if (!response.ok) {
                console.error("Error: Failed to send to Xero", response.status, response.statusText);
                setApiMsg(response.statusText || "Failed to send to Xero")
                setMsgColor(response.success ? 'green' : 'red')
                setOpenSnackbar(true)
                setResponseLoading(false)
                return;
            }

            response = await response.json();
            setApiMsg(response?.message || response?.err)
            setMsgColor(response.success ? 'green' : 'red')
            setOpenSnackbar(true)
            setResponseLoading(false)
            setTimeout(() => {
                handleCloseXeroConfModal()
            }, 1000);
            setCallBillApi(!callBillApi)

        } catch (error) {
            console.error("Error sending data to Xero:", error);
            setApiMsg(error)
            setMsgColor('red')
            setOpenSnackbar(true)
            setResponseLoading(false)
        }
    }

    return (
        <>
            <Dialog
                open={openXeroConfModal}
                onClose={handleCloseXeroConfModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure want to Send the bill to Xero ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ fontSize: "0.6rem" }} variant='outlined' onClick={() => handleCloseXeroConfModal()}>Cancel</Button>
                    <LoadingButton
                        sx={{ fontSize: "0.6rem" }} variant='contained'
                        onClick={() => handleSendToXero(data)}
                        loading={responseLoading}
                        loadingPosition="end"
                    >
                        <span>Send</span>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <SnackbarMsg apiMsg={apiMsg} open={openSnackbar} color={MsgColor} />
        </>
    )
}

export default SendToXero

